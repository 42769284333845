import { useState } from 'react';

const useDisplayMode = (initialMode = 'grid') => {
  const [display, setDisplay] = useState(initialMode);

  const toggleDisplay = (mode: string) => {
    if (display !== mode) {
      setDisplay(mode);
    }
  };

  return { display, toggleDisplay };
};

export default useDisplayMode;
