import React, { Suspense, lazy, Profiler, ComponentType } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import Layout from '../views/layout/Layout';
import RequireAuth from '../views/components/auth';
import RequireNonAuth from '../views/components/non-auth';
import AuthVerifyContainer from '../views/components/auth-verify';
import UnsubscribeNewsletterContainerPage from '../views/pages/unsubscribe-newsletter';
import ConfirmNewsletterUnsubscribeContainerPage from '../views/pages/confirm-newsletter-unsubscribe';
import AuthorPageContainer from '../views/pages/author-page';

const lazyRetry = function (
  componentImport: any
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const HomePageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/home'))
);
const ProductOverviewContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/product-overview'))
);
const RegistrationPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/registration'))
);
const StaticContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/static-page'))
);
const BooktokPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/booktok'))
);
const OrderStatusPage = lazy(() =>
  lazyRetry(() => import('../views/pages/order-status'))
);
const SearchPage = lazy(() =>
  lazyRetry(() => import('../views/pages/search-page'))
);
const ProfilePage = lazy(() =>
  lazyRetry(() => import('../views/pages/profile'))
);
const WsPayRedirectPage = lazy(() =>
  lazyRetry(() => import('../views/pages/wspay/s1_redirect'))
);
const ErrorPage = lazy(() =>
  lazyRetry(() => import('../views/pages/error-page'))
);
const NotFoundPage = lazy(() =>
  lazyRetry(() => import('../views/pages/not-found-page'))
);
const MaintenancePage = lazy(() =>
  lazyRetry(() => import('../views/pages/maintenance'))
);
const KidsBookContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/kids-book'))
);
const ForeignBookContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/foreign-book'))
);
const SchoolBookContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/school-book'))
);
const ActionsContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/actions'))
);
const BookmakerMagazineContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/bookmaker-magazine'))
);
const DelfiCareerPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/delfi-career'))
);

const BookstoresPage = lazy(() =>
  lazyRetry(() => import('../views/pages/bookstores'))
);
const BookStoreOverviewPage = lazy(() =>
  lazyRetry(() => import('../views/pages/bookstore-overview'))
);
const NewsPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/news'))
);
const NewsPageOverviewContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/news-overview'))
);
const WishlistPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/wishlist'))
);
const DelfiPremiumPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/delfi-premium'))
);
const ConfirmUserRegistrationContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/confirm-user-registration'))
);

const GamePageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/game'))
);
const MusicPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/music'))
);
const GiftPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/gift'))
);
const StarWayPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/starway'))
);
const BookPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/book'))
);
const WsPayErrorPage = lazy(() =>
  lazyRetry(() => import('../views/pages/wspay-error'))
);
const DelfiTicketsPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/delfi-tickets'))
);
const DelfiPrivacySecurityPage = lazy(() =>
  lazyRetry(() => import('../views/pages/delfi-security-privacy'))
);
const ComplaintPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/complaint'))
);
const LastViewedContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/last-viewed'))
);
const ActionsListContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/actions-list'))
);
const TopListsPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/top-lists'))
);
const FilmPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/film'))
);

const BoardGamesPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/board-games'))
);

const RussianBooksPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/russian-books'))
);

const MangePageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/mange'))
);

const MagyarPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/magyar'))
);

const CheckoutContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/checkout'))
);

const DelfiCollaborationPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/delfi-collaboration'))
);
const FaqContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/faq'))
);
const ProductListPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/product-list'))
);
const ConfirmNewsLetterContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/confirm-newsletter-subscribe'))
);
const ElementPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/element-page'))
);
const ForgotUserPasswordContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/forgot-user-password'))
);
const UserPasswordResetContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/user-password-reset'))
);

const ForgotUserEdenPasswordContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/forgot-user-eden-password'))
);
const UserEdenBooksPasswordResetContainerPage = lazy(() =>
  lazyRetry(() => import('../views/pages/eden-books-password-reset'))
);

const LoginPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/login'))
);
const AuthorsRedirectPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/authors-redirect-page'))
);
const GenresRedirectPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/genres-redirect-page'))
);

const GenrePageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/genre-page'))
);

const PublisherPageContainer = lazy(() =>
  lazyRetry(() => import('../views/pages/publisher-page'))
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<HomePageContainer />} />
      <Route path=":category/:id" element={<ProductOverviewContainer />} />
      <Route path="kupovina" element={<CheckoutContainer />} />
      <Route path="knjizare" element={<BookstoresPage />} />
      <Route path="knjizare/knjizara/:id" element={<BookStoreOverviewPage />} />
      <Route path="pretraga" element={<SearchPage />} />
      <Route path="profile">
        <Route
          index
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path=":part"
          element={
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="knjige" element={<BookPageContainer />} />
      <Route path="strane_knjige" element={<ForeignBookContainerPage />} />
      <Route path="skola" element={<SchoolBookContainerPage />} />
      <Route path="akcije" element={<ActionsContainerPage />} />
      <Route path="akcije/:param" element={<ActionsListContainerPage />} />
      <Route path="muzika" element={<MusicPageContainer />} />
      <Route path="gift" element={<GiftPageContainer />} />
      <Route path="starway" element={<StarWayPageContainer />} />
      <Route path="decja-knjiga" element={<KidsBookContainerPage />} />
      <Route path="booktok" element={<BooktokPageContainer />} />
      <Route path="top-liste" element={<TopListsPageContainer />} />
      <Route path="top-liste.html" element={<TopListsPageContainer />} />
      <Route path="video_igre_i_konzole" element={<GamePageContainer />} />
      <Route path="filmovi" element={<FilmPageContainer />} />
      <Route path="drustvene-igre" element={<BoardGamesPageContainer />} />
      <Route path="knigi-na-russkom" element={<RussianBooksPageContainer />} />
      <Route path="mange" element={<MangePageContainer />} />
      <Route path="magyar" element={<MagyarPageContainer />} />
      <Route path="error" element={<ErrorPage />} />
      <Route path="maintenance" element={<MaintenancePage />} />
      <Route path="vesti" element={<NewsPageContainer />} />
      <Route path="lista-zelja" element={<WishlistPageContainer />} />
      <Route path="vesti/vest/:id" element={<NewsPageOverviewContainer />} />
      <Route path="karijera" element={<DelfiCareerPageContainer />} />
      <Route path="saradnja" element={<DelfiCollaborationPageContainer />} />
      <Route path="delfi-premium" element={<DelfiPremiumPageContainer />} />
      <Route
        path="confirm-registration"
        element={
          <RequireNonAuth>
            <ConfirmUserRegistrationContainerPage />
          </RequireNonAuth>
        }
      />
      <Route
        path="newsletter-confirmation"
        element={<ConfirmNewsLetterContainerPage />}
      />
      <Route path="placanje-s1" element={<WsPayRedirectPage />} />
      <Route
        path="status-porudzbine-neocekivana-greska"
        element={<WsPayErrorPage />}
      />
      <Route path="status-porudzbine/:secret" element={<OrderStatusPage />} />
      <Route
        path="delfi_magazin"
        element={<BookmakerMagazineContainerPage />}
      />
      <Route path="faq" element={<FaqContainerPage />} />
      <Route path="lista-proizvoda" element={<ProductListPageContainer />} />
      <Route
        path="zaboravljena_lozinka"
        element={<ForgotUserPasswordContainerPage />}
      />
      <Route
        path="password-reset"
        element={<UserPasswordResetContainerPage />}
      />
      <Route
        path="zaboravljena_eden_lozinka"
        element={<ForgotUserEdenPasswordContainerPage />}
      />
      <Route
        path="eden/password-reset"
        element={<UserEdenBooksPasswordResetContainerPage />}
      />
      <Route path="ulaznice" element={<DelfiTicketsPageContainer />} />
      <Route path="registracija" element={<RegistrationPageContainer />} />
      <Route path="prijava" element={<LoginPageContainer />} />
      <Route path="reklamacije" element={<ComplaintPageContainer />} />
      <Route path="elements" element={<ElementPageContainer />} />
      <Route
        path="privatnost-i-sigurnost"
        element={<DelfiPrivacySecurityPage />}
      />
      <Route path="najcesca_pitanja" element={<DelfiPrivacySecurityPage />} />
      <Route path="poslednje-pogledano" element={<LastViewedContainerPage />} />
      <Route
        path="newsletter-odjava"
        element={<UnsubscribeNewsletterContainerPage />}
      />
      <Route path="autori/:param" element={<AuthorPageContainer />} />
      <Route
        path="newsletter-potvrda-odjave"
        element={<ConfirmNewsletterUnsubscribeContainerPage />}
      />
      <Route
        path=":category/zanr/:genreName"
        element={<GenrePageContainer />}
      />
      <Route
        path="/izdavac/:publisherName"
        element={<PublisherPageContainer />}
      />
      <Route path=":pageName" element={<StaticContainerPage />} />
      <Route
        path=":category/autor/:oldId"
        element={<AuthorsRedirectPageContainer />}
      />
      <Route
        path=":category/zanr/:oldId"
        element={<GenresRedirectPageContainer />}
      />
      <Route
        path=":category/top-lista.html"
        element={<TopListsPageContainer />}
      />
      <Route
        path="/bot/*"
        element={null}
      />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

const AppRouter = () => (
  <Profiler id="app" onRender={() => {}}>
    <Suspense fallback={null}>
      <AuthVerifyContainer>
        <RouterProvider router={router} />
      </AuthVerifyContainer>
    </Suspense>
  </Profiler>
);

export default AppRouter;
