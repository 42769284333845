import { BsListTask } from 'react-icons/bs';
import { MdGridView } from 'react-icons/md';
import React, { FC, useState } from 'react';

interface IProps {
  display: string;
  toggleDisplay: (display: string) => void;
}

const GridListToggle: FC<IProps> = ({ display, toggleDisplay }) => {
  return (
    <div className="col-auto mt-2">
      <BsListTask
        size="2em"
        className="cursor-pointer"
        color={`${display === 'list' ? '#005E9E' : ''}`}
        onClick={() => {
          return display !== 'list' ? toggleDisplay('list') : null;
        }}
      />
      <MdGridView
        size="2em"
        className="ms-4 cursor-pointer"
        color={`${display === 'grid' ? '#005E9E' : ''}`}
        onClick={() => {
          return display !== 'grid' ? toggleDisplay('grid') : null;
        }}
      />
    </div>
  );
};

export default GridListToggle;
