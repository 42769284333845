import { IProduct } from '../../../entities/product/types';
import React, { FC, useState } from 'react';
import StarRatings from '../star-ratings';
import { GrFormAdd } from 'react-icons/gr';
import { AiOutlineMinus } from 'react-icons/ai';
import './scss/style.scss';
import Authors from '../authors';
import { formatDateWithDots } from '../../../utilites';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../../utilites/product';

interface IProps {
  products: IProduct[];
  isRenderWithoutPrice?: boolean;
  classes?: string;
  cardClasses?: string;
}

const ProductList: FC<IProps> = ({
  products,
  isRenderWithoutPrice,
  classes,
  cardClasses
}: IProps) => {
  const prepareToggleCommentSectionData = () => {
    let toggleCommentsSectionByProductId: any = {};
    products.forEach((product: IProduct) => {
      toggleCommentsSectionByProductId[product.id] = false;
    });

    return toggleCommentsSectionByProductId;
  };

  const [
    isShowCommentsSectionByProductId,
    setIsShowCommentsSectionByProductId
  ] = useState(prepareToggleCommentSectionData());

  const handleOnClickToggleCommentsSection = (productId: string) => {
    const showCommentsSectionByProductId = {
      ...isShowCommentsSectionByProductId
    };

    showCommentsSectionByProductId[productId] =
      !showCommentsSectionByProductId[productId];

    setIsShowCommentsSectionByProductId(showCommentsSectionByProductId);
  };

  return (
    <>
      {products.map((product: IProduct) => {
        return (
          <div
            key={product.id}
            className={`row justify-content-start align-items-center gx-0 mt-4 ${classes}`}
          >
            <div className={`card bg-transparent border-0 ${cardClasses}`}>
              <Link
                to={product.navigateTo}
                className="font-color-default text-decoration-none"
              >
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="product-list-image-wrapper col-auto text-start ps-0 pe-0">
                      <img
                        src={product.images.s}
                        className="w-auto h-auto"
                        alt={product.title + '_img'}
                      />
                    </div>
                    <div className="col text-start">
                      <div className="row">
                        <div className="col">
                          <h6 className="card-text max-lines max-lines-3">
                            {product.title}
                          </h6>
                          <div className="mt-1">
                            <Authors
                              authors={product.authors}
                              classes="text-14"
                            />
                          </div>
                          <StarRatings rating={product.averageRank} />
                        </div>
                        <div className="col text-end">
                          {isRenderWithoutPrice ? (
                            product.topComments.length > 0 && (
                              <div className="col-auto">
                                <div
                                  className="ms-auto rounded-circle background-orange rounded-availability cursor-pointer plus-icon-size"
                                  onClick={() =>
                                    handleOnClickToggleCommentsSection(
                                      product.id
                                    )
                                  }
                                >
                                  {isShowCommentsSectionByProductId[
                                    product.id
                                  ] ? (
                                    <AiOutlineMinus size="2em" />
                                  ) : (
                                    <GrFormAdd size="2em" className="" />
                                  )}
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="row align-items-center">
                              <div className="col">
                                <span className="text-16 jost-semi-bold color-blue">
                                  {formatPrice(
                                    product.displayPrices.fullPrice,
                                    2
                                  )}{' '}
                                  RSD
                                </span>
                                {/*{parseFloat(product.priceList.discount) > 0 && (*/}
                                {/*  <p className="text-14 jost-extra-bold">*/}
                                {/*    <del>{product.priceList.discount}</del>*/}
                                {/*  </p>*/}
                                {/*)}*/}
                              </div>
                              <div className="col-sm-auto">
                                <div
                                  className={`ms-auto me-2 rounded-circle ${
                                    product.isAvailable
                                      ? 'background-delfi'
                                      : 'background-torch-red'
                                  } rounded-availability`}
                                ></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isRenderWithoutPrice &&
                    isShowCommentsSectionByProductId[product.id] && (
                      <div className="row mt-4 text-start">
                        {product.topComments.map((comment, index) => (
                          <div
                            key={index}
                            className="card background-gray border-0 border-bottom border-3 pt-3"
                          >
                            <div className="card-body">
                              {comment.title && (
                                <h6 className="card-title line-height-0">
                                  {comment.title}
                                </h6>
                              )}
                              {comment.createdAt && (
                                <span className="text-muted jost-medium text-13">
                                  {formatDateWithDots(
                                    new Date(comment.createdAt)
                                  )}
                                </span>
                              )}
                              <p className="card-text mt-4 max-lines max-lines-8">
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  <div className="border-bottom border-2 mt-3"></div>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductList;
