import {
  IBookstore,
  ILocation,
  IPrepareWorkingHoursLabels,
  IWorkingHours
} from '../../entities/bookstore/types';
import { IFactory } from '../types';
import Bookstore from '../../entities/bookstore/Bookstore';
import Config from '../../config';
import noImageBookstore from '../../views/assets/images/no-images/no-image-bookstore.png';
import { IProduct } from '../../entities/product/types';
import { replaceStringWith } from '../../utilites';
import URL_SUFFIX from '../../constants/UrlSuffix';

const BookstoreFactory: IFactory = class BookstoreFactory {
  private static createNavigateToUrl = (
    bookstoreOldId: number,
    bookstoreTitle: string
  ) => {
    const titleNoSpecialChars = replaceStringWith(
      bookstoreTitle,
      /[^a-zA-Z0-9 ]/g,
      ''
    );
    return `${bookstoreOldId}_${titleNoSpecialChars.replace(
      / /g,
      '_'
    )}_${URL_SUFFIX}`;
  };

  private static getWorkingHoursLabel(
    workingHours: IWorkingHours
  ): IPrepareWorkingHoursLabels {
    let workingHoursMonFriLabel = '';
    let workingHoursLabelOnTheWeekend = '';

    const days: (keyof IWorkingHours)[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    const weekdaysLabels = [
      'ponedeljak',
      'utorak',
      'sreda',
      'četvrtak',
      'petak'
    ];

    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      const label = weekdaysLabels[i];

      if (workingHours[day]) {
        //@ts-ignore
        if (/^\d{2}:\d{2} - \d{2}:\d{2}$/.test(workingHours[day])) {
          workingHours[day] += 'h';
        }

        workingHoursMonFriLabel += `${workingHours[day]} (${label}); `;
        continue;
      }
      workingHoursMonFriLabel += `(${label} ne radimo); `;
    }

    if (workingHours.Mon === workingHours.Fri) {
      workingHoursMonFriLabel = `${workingHours.Mon} (ponedeljak - petak); `;
    }

    if (!workingHours.Sat) {
      workingHoursLabelOnTheWeekend += 'Subotom ne radimo; ';
    }

    if (workingHours.Sat) {
      workingHoursLabelOnTheWeekend += `Subota: ${workingHours.Sat}; `;
    }

    if (!workingHours.Sun) {
      workingHoursLabelOnTheWeekend += 'Nedeljom ne radimo';
    }

    if (workingHours.Sun) {
      workingHoursLabelOnTheWeekend += `Nedelja: ${workingHours.Sun}`;
    }

    if (!workingHours.Sat && !workingHours.Sun) {
      workingHoursLabelOnTheWeekend = 'Vikendom ne radimo';
    }

    if (workingHours.Sat && workingHours.Sat === workingHours.Sun) {
      workingHoursLabelOnTheWeekend = `${workingHours.Sun} Vikendom`;
    }

    return {
      workingHoursMonFriLabel,
      workingHoursLabelOnTheWeekend
    };
  }

  private static addMediaUrlToImage(imageSrc: string | null | undefined) {
    return imageSrc ? `${Config.api.mediaUrl}${imageSrc}` : noImageBookstore;
  }

  private static addMediaUrlToImageGallery(imageGallery: string[]) {
    return imageGallery.map((imageSrc: string) => {
      return (imageSrc = this.addMediaUrlToImage(imageSrc));
    });
  }

  private static convertLocationCoordinatesToNumber(
    location: ILocation
  ): ILocation {
    return {
      lat: Number(location.lat),
      long: Number(location.long)
    };
  }

  public static create(bookstore: IBookstore) {
    return new Bookstore(
      bookstore._id,
      bookstore.oldId,
      bookstore.stockStatus,
      bookstore.status,
      this.addMediaUrlToImageGallery(bookstore.imgGallery),
      this.addMediaUrlToImage(bookstore.img),
      this.convertLocationCoordinatesToNumber(bookstore.location),
      bookstore.workingHours,
      bookstore.identAks,
      bookstore.embedMap,
      bookstore.email,
      bookstore.tel,
      bookstore.address,
      bookstore.mp,
      bookstore.bookstoreName,
      this.getWorkingHoursLabel(bookstore.workingHours),
      this.createNavigateToUrl(bookstore.oldId, bookstore.bookstoreName)
    );
  }
};

export default BookstoreFactory;
