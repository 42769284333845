import { createSearchParams, useSearchParams } from 'react-router-dom';
import { getQueryStringJsonParams } from '../utilites/url';

export const useNavigateParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const urlParamsToJson = getQueryStringJsonParams();

  const deleteParamIfExists = (params: Record<string, string | string[]>) => {
    Object.keys(params).forEach((key) => {
      if (
        key in urlParamsToJson &&
        (params[key] === '' || typeof params[key] === 'undefined')
      ) {
        delete urlParamsToJson[key];
        delete params[key];
      }
    });
  };

  return (params: Record<string, string | string[]>) => {
    deleteParamIfExists(params);
    createSearchParams(Object.assign({}, urlParamsToJson, params)).toString();
    setSearchParams(Object.assign({}, urlParamsToJson, params));
  };
};
