import React, { FC, useEffect, useState } from 'react';
import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Loader from '../../components/loader';
import Breadcrumbs from '../../components/breadcrumbs';
import Footer from '../../layout/footer';
import { fetchAuthorPageDataAction } from '../../../app/store/author-page/actions';
import { createMarkup, getShortDescription } from '../../../utilites';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import PaginationComponent from '../../components/pagination';
import { IProduct } from '../../../entities/product/types';
import ProductItem from '../../components/product-item-grid';
import { PriceDisplayTypes } from '../../../entities/section/types';
import './scss/style.scss';
import CategoryLinks from '../../components/category-links';
import { useNavigate } from 'react-router-dom';
import { getFormatedAuthorUrl } from '../../../utilites/author';
import { createSearchParamsAction } from '../../../app/store/searchParams/actions';
import {
  changeSearchParams,
  getDefaultSearchParams
} from '../../../utilites/url';
import { useNavigateParams } from '../../../hooks/useNavigateParams';
import DisplaySortFilters from '../../components/display-sort-filters';
import GridListToggle from '../../components/grid-list-toggle';
import useDisplayMode from '../../../hooks/useDisplayMode';
import ProductList from '../../components/product-list';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AuthorPageContainer: FC<Props> = ({
  ui,
  authorPageData,
  wishlist,
  searchParamsData,
  createSearchParamsAction,
  fetchAuthorPageDataAction
}: Props) => {
  const { param } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateWithParams = useNavigateParams();
  const location = useLocation();
  const [localSearchParams, setLocalSearchParams] = useState(searchParamsData);
  const { display, toggleDisplay } = useDisplayMode('grid');

  const languageMap = new Map([
    ['Sr', 'Srpski'],
    ['En', 'English'],
    ['Ru', 'Русский'],
    ['Hu', 'Magyar']
  ]);

  const navigate = useNavigate();
  const productsPerPage = 20;

  const pageNumberParam = searchParams.get('page')
    ? Number(searchParams.get('page'))
    : 1;

  const urlParams = getDefaultSearchParams(searchParams, {
    limit: productsPerPage,
    page: 1
  });

  useEffect(() => {
    if (param) {
      const id = param.split('-')[0];
      createSearchParamsAction(urlParams);
      fetchAuthorPageDataAction(id);
    }
  }, [param, location]);

  const paginate = (pageNumber: number) => {
    const params = searchParamsData;

    params.page = pageNumber;

    navigateWithParams(params);
    createSearchParamsAction(params);
  };

  const shortDescription = authorPageData.author.metaDescription
    ? getShortDescription(authorPageData.author.metaDescription, 0, 100)
    : getShortDescription(authorPageData.author.description || '', 0, 100);

  const getSelectedTab = (): string => {
    return languageMap.get(authorPageData.author.defaultLanguage || '') || '';
  };

  const [activeTab, setActiveTab] = useState(getSelectedTab());

  useEffect(() => {
    setActiveTab(getSelectedTab());
  }, [authorPageData.author]);

  const handleOnClickChangeTab = (activeTabName: string) => {
    setActiveTab(activeTabName);

    const languageKey = [...languageMap.entries()].find(
      ([key, value]) => value === activeTabName
    )?.[0];

    // @ts-ignore
    if (languageKey && authorPageData.author[`author${languageKey}Ref`]) {
      return navigate(
        `/autori/${getFormatedAuthorUrl(
          // @ts-ignore
          authorPageData.author[`author${languageKey}Ref`],
          // @ts-ignore
          authorPageData.author.authorRefDetails[`author${languageKey}Name`]
        )}`
      );
    }
  };

  const getTabs = () => {
    return [...languageMap].reduce((tabs, [key, language]) => {
      //@ts-ignore
      if (authorPageData.author[`author${key}Ref`]) {
        //@ts-ignore
        tabs.push({ name: language });
      }
      return tabs;
    }, []);
  };

  const handleOnClickChangeSearchParams = (
    params: Record<string, string | string[]>,
    isSelectFilters?: boolean
  ): void => {
    const searchParams = changeSearchParams(searchParamsData, params);
    createSearchParamsAction(searchParams);

    if (isSelectFilters) {
      return navigateWithParams(searchParams);
    }

    setLocalSearchParams({ ...searchParamsData });
  };

  const renderGrid = () => {
    return authorPageData.products.map((product: IProduct) => {
      return (
        <div className="col-6 col-md-6 col-lg-3 mb-3" key={product.id}>
          <ProductItem
            product={product}
            wishListProductIds={wishlist.productIds}
            renderOnStock
            priceDisplayType={PriceDisplayTypes.standard}
          />
        </div>
      );
    });
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <title> Stranica autora | Delfi knjižare </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {ui.loaders.includes(MiddlewareEntities.AUTHOR) ? (
        <Loader />
      ) : authorPageData.author.authorName ? (
        <>
          <Helmet>
            <meta
              name="title"
              content={`${
                authorPageData.author.metaTitle
                  ? authorPageData.author.metaTitle
                  : authorPageData.author.authorName
              } | Delfi knjižare`}
            />
            <meta name="description" content={shortDescription} />
          </Helmet>
          <div className="mt-5 container">
            <div className="row">
              <div className="col-md-12">
                <Breadcrumbs
                  items={[
                    {
                      name: 'Početna',
                      isActive: false,
                      url: '/'
                    },
                    {
                      name: authorPageData.author.authorName,
                      isActive: true
                    }
                  ]}
                />
              </div>
            </div>
            <div className="row justify-content-center text-center align-items-center">
              <div className="col-md-12">
                <h1 className="h1-mod7">{authorPageData.author.authorName}</h1>
              </div>
            </div>
            {authorPageData.author.description ||
            authorPageData.author.authorImg ? (
              <div className="row mt-4">
                {authorPageData.author.authorImg ? (
                  <div
                    className={`${
                      authorPageData.author.description
                        ? 'col-md-auto'
                        : 'col-md'
                    } text-center ${
                      authorPageData.author.description ? 'text-md-start' : ''
                    }`}
                  >
                    <div>
                      <img
                        src={authorPageData.author.authorImg}
                        alt={authorPageData.author.authorImg + 'Image'}
                        className="img-fluid fit-cover author-img"
                      />
                    </div>
                  </div>
                ) : null}
                {authorPageData.author.description ? (
                  <div className="col-md text-center text-md-start mt-sm-4 mt-md-0 p-1 jost-regular text-muted">
                    <div
                      className="author-description"
                      dangerouslySetInnerHTML={createMarkup(
                        authorPageData.author.description
                      )}
                    ></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="row mt-4 align-items-center">
              <CategoryLinks
                tabs={getTabs()}
                activeTab={activeTab}
                handleOnChangeTab={handleOnClickChangeTab}
                isScrollable
              />
            </div>

            <div className="row mt-4 align-items-center">
              <div className="col-md-12 mb-3 mb-md-0">
                <div className="row justify-content-end align-items-center">
                  <DisplaySortFilters
                    cssClassesDisplayWrapper="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
                    cssClassesSortWrapper="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2"
                    searchParamSort={searchParams.get('sort')}
                    searchParamLimit={searchParams.get('limit')}
                    changeSelectFilterOnClick={handleOnClickChangeSearchParams}
                  />
                  <GridListToggle
                    display={display}
                    toggleDisplay={toggleDisplay}
                  />
                </div>
              </div>
              <div
                className={`row ${
                  authorPageData.products.length < 4
                    ? 'justify-content-center'
                    : ''
                }`}
              >
                {display === 'list' ? (
                  <ProductList
                    products={authorPageData.products}
                    cardClasses="p-2"
                  />
                ) : (
                  renderGrid()
                )}
              </div>
              {/*{authorPageData.products.map(*/}
              {/*  (product: IProduct, index: number) => (*/}
              {/*    <div*/}
              {/*      className="col-6 col-md-6 col-lg-3 mb-3"*/}
              {/*      key={product.id}*/}
              {/*    >*/}
              {/*      <ProductItem*/}
              {/*        product={product}*/}
              {/*        wishListProductIds={wishlist.productIds}*/}
              {/*        renderOnStock*/}
              {/*        priceDisplayType={PriceDisplayTypes.standard}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  )*/}
              {/*)}*/}
            </div>
            {authorPageData.recordsTotal > productsPerPage ? (
              <div className="row justify-content-center text-center mt-5">
                <div className="position-relative">
                  <PaginationComponent
                    itemsCount={authorPageData.recordsTotal}
                    itemsPerPage={productsPerPage}
                    currentPage={pageNumberParam}
                    setCurrentPage={paginate}
                    alwaysShown={true}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <Footer />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ui: state.ui,
  authorPageData: state.authorPageData,
  wishlist: state.wishlist.productIds,
  searchParamsData: state.searchParams.items
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { fetchAuthorPageDataAction, createSearchParamsAction },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorPageContainer);
