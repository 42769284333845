import React, { FC } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import {
  addToWishListAction,
  removeFromWishListAction
} from '../../../app/store/wishlist/actions';
import { addToCartAction } from '../../../app/store/cart/actions';
import { connect } from 'react-redux';
import Button from '../button';
import { BsCartFill, BsHeart, BsHeartFill } from 'react-icons/bs';
import { ReactComponent as CartImageIcon } from '../../assets/images/delfi-cart.svg';
import './scss/style.scss';
import useAddToCartAndWishlist from '../../../hooks/useAddToCartAndWishlist';
import { setToggleChangeCartAction } from '../../../app/store/toggle-add-to-cart/actions';
import { AppState } from '../../../app/store/rootReducers';

interface IProps {
  productId: string;
  wishListProductIds: string[];
  isProductAvailable: boolean;
  classes?: string;
}

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IProps;

const ProductIconsContainer: FC<Props> = ({
  productId,
  wishListProductIds,
  isProductAvailable,
  classes,
  addToWishListAction,
  removeFromWishListAction,
  addToCartAction,
  setToggleChangeCartAction,
  isChanged
}: Props) => {
  const { addToBasket, handleOnClickAddToWishList, handleOnClickAddToCart } =
    useAddToCartAndWishlist(
      productId,
      wishListProductIds,
      addToCartAction,
      addToWishListAction,
      removeFromWishListAction
    );

  const handleOnClickAddToCartLocal = (event: any) => {
    if (!isChanged) {
      handleOnClickAddToCart(event);
      setToggleChangeCartAction(true);

      setTimeout(() => {
        setToggleChangeCartAction(false);
      }, 1000);
    }
  };

  const handleOnClickAddToWishListLocal = (event: any) => {
    if (!isChanged) {
      handleOnClickAddToWishList(event);
      setToggleChangeCartAction(true);

      setTimeout(() => {
        setToggleChangeCartAction(false);
      }, 1000);
    }
  };

  return (
    <div className={`row gx-2 gx-md-4 mt-3 mt-md-0 ${classes}`}>
      <div className="col-6">
        <Button
          classes={`w-100 btn-product-icon bg-icon-wish-list d-flex justify-content-center align-items-center ${
            isChanged ? 'opacity-93' : ''
          }`}
          ariaLabelName="lista-zelja"
          onClick={(event) => handleOnClickAddToWishListLocal(event)}
        >
          {wishListProductIds.includes(productId) ? (
            <BsHeartFill
              color="white"
              size="1.2em"
              style={{ strokeWidth: '0.5' }}
            />
          ) : (
            <BsHeart
              color="white"
              size="1.2em"
              style={{ strokeWidth: '0.5' }}
            />
          )}
        </Button>
      </div>
      <div className="col-6 position-relative">
        {addToBasket ? (
          <Button
            ariaLabelName="korpa"
            classes="bg-icon-basket btn-product-icon cart-full-icon-wrapper d-flex justify-content-center align-items-center position-absolute"
          >
            <BsCartFill
              color="white"
              size="1.2em"
              style={{ strokeWidth: '0.5' }}
              className="mt-minus-4 bs-cart"
            />
          </Button>
        ) : null}

        {isProductAvailable ? (
          <Button
            ariaLabelName="korpa"
            classes={`w-100 btn-product-icon bg-icon-basket d-flex justify-content-center align-items-center ${
              isChanged ? 'opacity-93' : ''
            }`}
            onClick={(event) => handleOnClickAddToCartLocal(event)}
          >
            <CartImageIcon width="1.2em" height="1.2em" />
          </Button>
        ) : (
          <Button
            ariaLabelName="korpa-animacija"
            classes="w-100 btn-product-icon bg-black-shadows d-flex justify-content-center align-items-center"
          >
            <CartImageIcon width="1.2em" height="1.2em" />
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isChanged: state.toggleChangeCartData.isChanged
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      addToWishListAction,
      addToCartAction,
      removeFromWishListAction,
      setToggleChangeCartAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductIconsContainer);
