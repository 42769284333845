import { IFactory } from '../../types';
import { IGenresBannerCarouselContent } from '../../../entities/section/types';
import { replaceDotWithUrl } from '../../../utilites/product';
import GenresBannerCarouselContent from '../../../entities/section/genres-banner-carousel/GenresBannerCarouselContent';

const GenresBannerCarouselContentFactory: IFactory = class GenresBannerCarouselFactory {
  static create(genresBannerCarousel: IGenresBannerCarouselContent) {
    return new GenresBannerCarouselContent(
      genresBannerCarousel._id,
      genresBannerCarousel.title,
      genresBannerCarousel.category,
      genresBannerCarousel.buttonLabel,
      genresBannerCarousel.description,
      replaceDotWithUrl(genresBannerCarousel.desktopImg),
      replaceDotWithUrl(genresBannerCarousel.mobileImg),
      genresBannerCarousel.url,
      genresBannerCarousel.titleColor,
      genresBannerCarousel.descriptionColor,
      genresBannerCarousel.genres,
      genresBannerCarousel.startAt,
      genresBannerCarousel.endAt
    );
  }
};

export default GenresBannerCarouselContentFactory;
