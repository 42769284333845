/* eslint-disable @typescript-eslint/brace-style */
import { IGenresBannerCarouselContent } from '../types';

export default class GenresBannerCarouselContent
  implements IGenresBannerCarouselContent
{
  _id: string;

  title: string;

  category: string;

  buttonLabel: string;

  description: string;

  desktopImg: string;

  mobileImg: string;

  url: string;

  titleColor: string;

  descriptionColor: string;

  genres: string[];

  startAt: string | null;

  endAt: string | null;

  constructor(
    id: string,
    title: string,
    category: string,
    buttonLabel: string,
    description: string,
    desktopImg: string,
    mobileImg: string,
    url: string,
    titleColor: string,
    descriptionColor: string,
    genres: string[],
    startAt: string | null,
    endAt: string | null
  ) {
    this._id = id;
    this.title = title;
    this.category = category;
    this.buttonLabel = buttonLabel;
    this.description = description;
    this.desktopImg = desktopImg;
    this.mobileImg = mobileImg;
    this.url = url;
    this.titleColor = titleColor;
    this.descriptionColor = descriptionColor;
    this.genres = genres;
    this.startAt = startAt;
    this.endAt = endAt;
  }
}
