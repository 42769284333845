export const getFullUrl = (): string => {
  return window.location.href;
};

export const getUrlPath = (): string => {
  return window.location.pathname;
};

// export const getParamsFromUrlOnLoadPage = () => {
//   return queryString.parse(window.location.search);
// };

export const changeUrl = (url: string) => {
  window.history.pushState({ path: url }, '', url);
};

export const getUrlParameter = (url: string, parm: string): string | null => {
  const urlObject = new URL(url);
  return urlObject.searchParams.get(parm);
};

export const changeUrlParams = (value: string, param: string): string => {
  const fullUrl = window.location.href;
  const re = new RegExp(`([?&])${param}=.*?(&|$)`, 'i');

  if (getUrlParameter(fullUrl, param)) {
    return fullUrl.replace(re, `$1${param}=${value}$2`);
  }

  if (fullUrl.indexOf('?') > -1) {
    return `${fullUrl}&${param}=${value}`;
  }

  return `${fullUrl}?${param}=${value}`;
};

export const removeParamsByNameFromUrl = (paramName: string): string => {
  const fullUrl = window.location.href;
  const urlParts = fullUrl.split('?');
  if (urlParts.length === 1) {
    return fullUrl;
  }

  const params = new URLSearchParams(urlParts[1]);
  params.delete(paramName);
  return `${urlParts[0]}?${params.toString()}`;
};

const removeParamsFromUrl = (params: any, paramName: string) => {
  const url = removeParamsByNameFromUrl(paramName);
  changeUrl(url);
  delete params[paramName];

  return params;
};

export const getQueryStringJsonParams = () => {
  return Object.fromEntries(new URLSearchParams(window.location.search));
};

export const deleteParamIfExists = (
  params: Record<string, string | string[]>
) => {
  const urlParamsToJson = getQueryStringJsonParams();
  Object.keys(params).forEach((key) => {
    if (
      key in urlParamsToJson &&
      (params[key] === '' || typeof params[key] === 'undefined')
    ) {
      delete urlParamsToJson[key];
      delete params[key];
    }
  });

  return Object.assign({}, urlParamsToJson, params);
};

export const getDefaultSearchParams = (
  searchParams: URLSearchParams,
  defaultParams: any
) => {
  const urlParams: any = {};

  for (const [key, val] of searchParams.entries()) {
    urlParams[key] = val;
  }

  for (const [key, val] of Object.entries(defaultParams)) {
    if (!urlParams.hasOwnProperty(key)) {
      urlParams[key] = val;
    }
  }

  return urlParams;
};

export const getStaticUrlPartName = () => {
  return getUrlPath().replace('/', '');
};

export const changeSearchParams = (
  searchParams: any,
  params: Record<string, string | string[]>
): any => {
  for (const [key, value] of Object.entries(params)) {
    searchParams[key] = value;
  }

  if (params.limit) {
    searchParams.page = 1;
  }

  return searchParams;
};
