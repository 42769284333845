import ActionTypes from '../../../constants/ActionTypes';
import { ISetOnBannerAction } from './types';

const initialState: { bannerImpressions: string[]; bannerClicks: string[] } = {
  bannerImpressions: [],
  bannerClicks: []
};

const gtmReducer = (state = initialState, action: ISetOnBannerAction) => {
  switch (action.type) {
    case ActionTypes.GTM.SET_ON_BANNER_IMPRESSION:
      return {
        ...state,
        bannerImpressions: state.bannerImpressions.includes(action.payload)
          ? state.bannerImpressions
          : [...state.bannerImpressions, action.payload]
      };
    case ActionTypes.GTM.SET_ON_BANNER_CLICK:
      return {
        ...state,
        bannerClicks: state.bannerClicks.includes(action.payload)
          ? state.bannerClicks
          : [...state.bannerClicks, action.payload]
      };
    default:
      return state;
  }
};

export default gtmReducer;
