import React, { ChangeEvent, FC } from 'react';
import Form from 'react-bootstrap/Form';
import DisplayLimitFilter from '../display-limit-filter';

interface IProps {
  searchParamSort: string | null;
  searchParamLimit: string | null;
  changeSelectFilterOnClick: (
    params: Record<string, string | string[]>,
    isSelectFilters?: boolean
  ) => void;
  searchPageSortLabel?: boolean;
  cssClassesDisplayWrapper?: string;
  cssClassesSortWrapper?: string;
}

const DisplaySortFilters: FC<IProps> = ({
  searchParamSort,
  searchParamLimit,
  changeSelectFilterOnClick,
  searchPageSortLabel,
  cssClassesDisplayWrapper,
  cssClassesSortWrapper
}: IProps) => {
  return (
    <>
      <div
        className={`justify-content-start text-start ${cssClassesDisplayWrapper}`}
      >
        <div className="form-group">
          <label
            htmlFor="sort"
            className="jost-semi-bold text-muted mt-2 text-11"
          >
            SORTIRANJE
          </label>
          <Form.Select
            name="sort"
            id="sort"
            className="form-select mb-3"
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              changeSelectFilterOnClick(
                {
                  sort: e.target.value
                },
                true
              )
            }
            value={searchParamSort ? searchParamSort : ''}
          >
            <option value="order_asc">
              {searchPageSortLabel ? 'Preporučeno' : 'Hronološki'}
            </option>
            <option value="price_asc">Cena rastuća</option>
            <option value="price_desc">Cena opadajuća</option>
            <option value="title_asc">Nazivi od A - Š</option>
            <option value="title_desc">Nazivi od Š - A</option>
          </Form.Select>
        </div>
      </div>
      <DisplayLimitFilter
        changeSelectFilterOnClick={changeSelectFilterOnClick}
        searchParamLimit={searchParamLimit}
        classes={cssClassesSortWrapper}
      />
    </>
  );
};

export default DisplaySortFilters;
