import React, { FC, useState } from 'react';
import { IProduct } from '../../../entities/product/types';
import ProductStickers from '../product-stickers';
import StarRatings from '../star-ratings';
// import { useMediaQuery } from 'react-responsive';
import ProductNumber from '../product-number';
import { Link, useNavigate } from 'react-router-dom';
import './scss/style.scss';
import ProductPrice from '../product-price';
import Authors from '../authors';
import ProductIconsContainer from '../product-icons';
import { PriceDisplayTypes } from '../../../entities/section/types';
import { createNavigateToUrlWithPriceDisplayType } from '../../../utilites/product';

interface IProps {
  product: IProduct;
  wishListProductIds: string[];
  renderOnStock?: boolean;
  isRenderProductNumber?: boolean | null;
  productNumber?: number;
  handleOnClick?: () => void;
  priceDisplayType?: PriceDisplayTypes;
}

const ProductItem: FC<IProps> = ({
  product,
  wishListProductIds,
  renderOnStock,
  isRenderProductNumber,
  productNumber,
  handleOnClick,
  priceDisplayType
}: IProps) => {
  // const isMobile = useMediaQuery({ query: '(max-width: 999px)' });

  // const overlayClasses = isMobile ? 'overlay-opacity' : '';

  return (
    <div className="card border-0 fit-cover bg-white card-slider p-3 text-start h-100 cursor-pointer">
      <Link
        className="text-decoration-none font-color-default no-color-hover"
        to={createNavigateToUrlWithPriceDisplayType(
          product.navigateTo,
          priceDisplayType
        )}
      >
        {isRenderProductNumber && productNumber ? (
          <ProductNumber
            number={productNumber}
            classes="background-orange p-2"
            numberClasses="text-21"
            styleType={{ minWidth: '42px' }}
          />
        ) : null}
        <div className="position-relative d-flex mt-2">
          <div className="mx-auto d-flex product-slide-img-container">
            <div className="position-relative align-self-center">
              <img
                src={product.images.l}
                alt={`${product.title}_img`}
                className="img-product-shadow img-fluid product-slide-img-height"
              />
              {priceDisplayType !== PriceDisplayTypes.eBook ? (
                <ProductStickers stickers={product.displayStickers} />
              ) : null}
            </div>
            {/*<OverlayIcons*/}
            {/*  classes={overlayClasses}*/}
            {/*  productId={product.id}*/}
            {/*  wishListProductIds={wishListProductIds}*/}
            {/*/>*/}
          </div>
        </div>
      </Link>
      <div className="card-body p-0 pt-md-3">
        {priceDisplayType !== PriceDisplayTypes.eBook ? (
          <ProductIconsContainer
            productId={product.id}
            wishListProductIds={wishListProductIds}
            isProductAvailable={product.isAvailable}
          />
        ) : null}
        <Link
          className="text-decoration-none"
          to={createNavigateToUrlWithPriceDisplayType(
            product.navigateTo,
            priceDisplayType
          )}
        >
          <StarRatings classes="mb-2" rating={product.averageRank} />
          <h2 className="card-title h2-mod6-2 overflow-hidden card-title-define-height max-lines max-lines-2">
            {product.title}
          </h2>
        </Link>
        <div className="max-lines-1 max-lines height-20 mb-sm-2 mt-1">
          <Authors
            authors={product.authors}
            classes="text-14"
            handleOnClick={handleOnClick}
          />
        </div>
        <Link
          className="text-decoration-none"
          to={createNavigateToUrlWithPriceDisplayType(
            product.navigateTo,
            priceDisplayType
          )}
        >
          {renderOnStock ? (
            <div className="d-flex justify-content-center">
              <div className="col-10">
                <ProductPrice
                  displayPrices={product.displayPrices}
                  productType={product.productType}
                  priceDisplayType={priceDisplayType}
                  isOnAction={product.isActionOnFly}
                />
              </div>
              <div className="col-2">
                <div
                  className={`ms-auto me-2 rounded-circle ${
                    product.isAvailable
                      ? 'background-delfi'
                      : 'background-torch-red'
                  } rounded-availability`}
                ></div>
              </div>
            </div>
          ) : (
            <div>
              <ProductPrice
                displayPrices={product.displayPrices}
                productType={product.productType}
                priceDisplayType={priceDisplayType}
                isOnAction={product.isActionOnFly}
              />
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
