import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  hideNewsletterCookieAction,
  isShowNewsletterCookieAction
} from '../../../app/store/newsletter-cookie-modal/actions';
import HeaderModal from '../../pages/order-status/modals/components/header/HeaderModal';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import FieldInput from '../forms/fields/FieldInput';
import { composeValidators, email, required } from '../forms/validators';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Spinner from '../spinner/Spinner';
import Button from '../button';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../../config';
import { subscribeNewsLetterAction } from '../../../app/store/newsletter/actions';
import { recognizeSearchCrawler } from '../../../utilites';
import { getJsonFromCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';

const NewsletterCookieModal = () => {
  const refRecaptcha = React.useRef(null);

  const showModal = useSelector((state: any) => {
    return state.newsletterCookieData.showNewsletterCookieModal;
  });

  const ui = useSelector((state: any) => state.ui);
  const newsLetterData = useSelector((state: any) => state.newsLetterData);

  const dispatch = useDispatch();

  const handleOnHide = () => {
    dispatch(hideNewsletterCookieAction());
  };

  useEffect(() => {
    const cookieValue = getJsonFromCookie(CookieNames.NEWSLETTER_MODAL, '{}');
    if (
      Config.showNewsletterCookieModal &&
      (!cookieValue || Object.keys(cookieValue).length === 0) &&
      !recognizeSearchCrawler()
    ) {
      const timer = setTimeout(() => {
        dispatch(isShowNewsletterCookieAction());
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleOnSubmit = async (values: any) => {
    //@ts-ignore
    const recaptcha = await refRecaptcha.current.executeAsync();
    const formData = {
      ...values,
      recaptcha,
      isForNewsLetterForModal: true
    };

    dispatch(subscribeNewsLetterAction(formData));
  };

  if (!showModal) return null;

  return (
    <Modal show={showModal} size="lg" centered onHide={handleOnHide}>
      <HeaderModal handleOnClickHide={handleOnHide} />
      <Modal.Body>
        <div className="row justify-content-center text-center mt-4">
          <div className="col-md-12">
            <h6>Prijavi se na newsletter</h6>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-12 mt-4">
            <p>Budi u toku sa svim novitetima, akcijama i pogodnostima</p>
          </div>
        </div>
        <div className="row justify-content-center text-16 jost-regular text-center">
          <div className="col-md-6">
            <Form
              onSubmit={handleOnSubmit}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values
              }) => (
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="mb-3 text-14">
                    <FieldInput
                      name="email"
                      type="text"
                      placeholder="Email"
                      validators={composeValidators(required, email)}
                    />
                  </div>
                  {newsLetterData.message &&
                  newsLetterData.isForNewsLetterForModal ? (
                    <div className="mt-1 ml-1 text-14">
                      <span
                        className={`${
                          newsLetterData.status === 'success'
                            ? 'alert-success'
                            : 'alert-danger'
                        }`}
                      >
                        {newsLetterData.message}
                      </span>
                    </div>
                  ) : null}
                  {!newsLetterData.status ? (
                    <div className="row justify-content-center text-center pb-3">
                      <div className="col-md-6">
                        {ui.loaders.includes(MiddlewareEntities.NEWSLETTER) ? (
                          <Spinner />
                        ) : (
                          <Button
                            type="submit"
                            classes="btn background-orange shadow-none border-0 p-2 w-100 jost-extra-bold text-14"
                          >
                            Pošaljite
                          </Button>
                        )}
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                        <Button
                          onClick={handleOnHide}
                          type="button"
                          classes="btn background-link-water shadow-none p-2 border-0 w-100 jost-extra-bold text-14"
                        >
                          Odustanite
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <ReCAPTCHA
                    ref={refRecaptcha}
                    sitekey={Config.recaptchaPublicKey}
                    size="invisible"
                  />
                </form>
              )}
            ></Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewsletterCookieModal;
