export const genresBannerCarouselSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        buttonLabel: { type: 'string' },
        description: { type: 'string' },
        desktopImg: { type: 'string' },
        mobileImg: { type: 'string' },
        title: { type: 'string' },
        url: { type: ['null', 'string'] },
        _id: { type: 'string' },
        genres: {
          type: 'array',
          items: { type: 'string' },
          minItems: 1
        }
      },
      required: [
        'buttonLabel',
        'description',
        'desktopImg',
        'mobileImg',
        'title',
        'genres',
        '_id'
      ]
    }
  },
  required: ['type', 'content']
};
