import React, { ChangeEvent, FC } from 'react';

interface IProps {
  changeSelectFilterOnClick: (
    params: Record<string, string | string[]>,
    isSelectFilters?: boolean
  ) => void;
  searchParamLimit: string | null;
  classes?: string;
}

const DisplayLimitFilter: FC<IProps> = ({
  classes,
  changeSelectFilterOnClick,
  searchParamLimit
}: IProps) => {
  return (
    <div className={`justify-content-start text-start ${classes}`}>
      <div className="form-group">
        <label
          htmlFor="display"
          className="jost-semi-bold text-muted mt-2 text-11"
        >
          PRIKAŽI
        </label>
        <select
          id="display"
          name="display"
          className="form-select mb-3"
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            changeSelectFilterOnClick(
              {
                limit: e.target.value
              },
              true
            )
          }
          value={searchParamLimit ? searchParamLimit : ''}
        >
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
};

export default DisplayLimitFilter;
