import { IAuthorPageDataState, ISetAuthorPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IAuthorPageDataState = {
  author: {
    _id: '',
    oldId: 0,
    authorName: '',
    status: true,
    authorRefDetails: {}
  },
  products: [],
  recordsTotal: 0
};
const authorPageDataReducer = (
  state = initialState,
  action: ISetAuthorPageDataAction
): IAuthorPageDataState => {
  switch (action.type) {
    case ActionTypes.AUTHOR_PAGE.SET_AUTHOR_PAGE_DATA:
      return {
        ...state,
        author: action.payload.author,
        products: action.payload.products,
        recordsTotal: action.payload.recordsTotal
      };
  }
  return state;
};

export default authorPageDataReducer;
