import { ISection } from '../entities/section/types';
import { Ajv as typeAjv } from 'ajv';
import Ajv from 'ajv';
import SimpleCarouselContentFactory from '../factories/sections/simple-carousel/SimpleCarouselContentFactory';
import TabsCarouselContentFactory from '../factories/sections/tabs-carousels/TabsCarouselContentFactory';
import SectionFactory from '../factories/sections/SectionContentFactory';
import ProminentCarouselContentFactory from '../factories/sections/prominent-carousel/ProminentCarouselContentFactory';
import TopListsContentFactory from '../factories/sections/top-lists/TopListsContentFactory';
import FlatCarouselsContentFactory from '../factories/sections/flat-carousels/FlatCarouselsContentFactory';
import { simpleCarouselsSchema } from '../validators/sections/simpleCarouselsSchema';
import { tabCarouselsSchema } from '../validators/sections/tabCarouselsSchema';
import { prominentCarouselsSchema } from '../validators/sections/prominentCarouselsSchema';
import { topListsSchema } from '../validators/sections/topListsSchema';
import FlatSquareCarouselsContentFactory from '../factories/sections/flat-carousels/FlatSquareCarouselsFactory';
import { twinBannersSchema } from '../validators/sections/twinBannersSchema';
import TwinBannersContentFactory from '../factories/sections/twin-banners/TwinBannersContentFactory';
import ThreePartCarouselsContentFactory from '../factories/sections/three-part-carousels/ThreePartCarouselsContentFactory';
import { threePartCarouselsSchema } from '../validators/sections/threePartCarouselsSchema';
import { widePromoBannersSchema } from '../validators/sections/widePromoBannersSchema';
import WidePromoBannersContentFactory from '../factories/sections/wide-promo-banners/WidePromoBannersContentFactory';
import ThreeCollectionCarouselsContentFactory from '../factories/sections/three-collection-carousels/ThreeCollectionCarouselsContentFactory';
import { threeCollectionCarouselsSchema } from '../validators/sections/threeCollectionCarouselsSchema';
import { SectionTypes } from '../constants/SectionTypes';
import InfoIconsContentFactory from '../factories/sections/info-icons/InfoIconsContentFactory';
import { infoIconsSchema } from '../validators/sections/infoIconsSchema';
import { logoWidgetCarouselsSchema } from '../validators/sections/logoWidgetCarouselsSchema';
import LogoWidgetCarouselsFactory from '../factories/sections/logo-widget-carousels/LogoWidgetCarouselsFactory';
import { seoWidgetCarouselsSchema } from '../validators/sections/seoWidgetCarouselsSchema';
import SeoWidgetCarouselsFactory from '../factories/sections/seo-widget-carousels/SeoWidgetCarouselsFactory';
import { genresBannerCarouselSchema } from '../validators/sections/genresBannerCarouselSchema';
import GenresBannerCarouselContentFactory from '../factories/sections/genres-banner-carousel/GenresBannerCarouselFactory';

export default class SectionDataFacade {
  private static ajvInstance: typeAjv;

  private static getAjvInstance(): typeAjv {
    if (!this.ajvInstance) {
      this.ajvInstance = new Ajv();
    }

    return this.ajvInstance;
  }

  static getValidateItem(sectionItem: ISection) {
    if (sectionItem) {
      const ajv = this.getAjvInstance();
      switch (sectionItem.type) {
        case SectionTypes.SIMPLE_CAROUSELS:
        case SectionTypes.FLAT_CAROUSELS:
          if (ajv.validate(simpleCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.TAB_CAROUSELS:
          if (ajv.validate(tabCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.PROMINENT_CAROUSELS:
          if (ajv.validate(prominentCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.TOP_LISTS:
          if (ajv.validate(topListsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.LAST_VIEWED:
          return sectionItem;
        case SectionTypes.NEWS_CORNER:
          return sectionItem;
        case SectionTypes.KIDS_BOOK_PG_RATING_SECTION:
          return sectionItem;
        case SectionTypes.TWIN_BANNERS:
          if (ajv.validate(twinBannersSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.INFO_ICONS:
          if (ajv.validate(infoIconsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.THREE_PART_CAROUSELS:
          if (ajv.validate(threePartCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.WIDE_PROMO_BANNERS:
          if (ajv.validate(widePromoBannersSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.GENRES_BANNER_CAROUSEL:
          if (ajv.validate(genresBannerCarouselSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.THREE_COLLECTION_CAROUSELS:
          if (ajv.validate(threeCollectionCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.LOGO_WIDGET_CAROUSELS:
          if (ajv.validate(logoWidgetCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        case SectionTypes.SEO_WIDGET_CAROUSELS:
          if (ajv.validate(seoWidgetCarouselsSchema, sectionItem)) {
            return sectionItem;
          }
          break;
        default:
          return sectionItem;
      }
    }
  }

  private static getValidateItems(sectionData: ISection[]) {
    return sectionData.filter((item: ISection) => {
      return this.getValidateItem(item);
    });
  }

  static generateSectionItem(sectionItem: ISection) {
    switch (sectionItem.type) {
      case SectionTypes.SIMPLE_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: SimpleCarouselContentFactory.create(sectionItem.content)
        });
      case SectionTypes.TAB_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: TabsCarouselContentFactory.create(sectionItem.content)
        });
      case SectionTypes.PROMINENT_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: ProminentCarouselContentFactory.create(sectionItem.content)
        });
      case SectionTypes.TOP_LISTS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: TopListsContentFactory.create(sectionItem.content)
        });
      case SectionTypes.FLAT_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: FlatCarouselsContentFactory.create(sectionItem.content)
        });
      case SectionTypes.FLAT_SQUARE_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: FlatSquareCarouselsContentFactory.create(sectionItem.content)
        });
      case SectionTypes.LAST_VIEWED:
        return SectionFactory.create({
          type: sectionItem.type,
          content: {}
        });
      case SectionTypes.TWIN_BANNERS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: TwinBannersContentFactory.create(sectionItem.content)
        });
      case SectionTypes.INFO_ICONS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: InfoIconsContentFactory.create(sectionItem.content)
        });
      case SectionTypes.THREE_PART_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: ThreePartCarouselsContentFactory.create(sectionItem.content)
        });
      case SectionTypes.WIDE_PROMO_BANNERS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: WidePromoBannersContentFactory.create(sectionItem.content)
        });
      case SectionTypes.GENRES_BANNER_CAROUSEL:
        return SectionFactory.create({
          type: sectionItem.type,
          content: GenresBannerCarouselContentFactory.create(
            sectionItem.content
          )
        });
      case SectionTypes.THREE_COLLECTION_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: ThreeCollectionCarouselsContentFactory.create(
            sectionItem.content
          )
        });
      case SectionTypes.NEWS_CORNER:
        return SectionFactory.create({
          type: sectionItem.type,
          content: []
        });
      case SectionTypes.KIDS_BOOK_PG_RATING_SECTION:
        return SectionFactory.create({
          type: sectionItem.type,
          content: []
        });
      case SectionTypes.LOGO_WIDGET_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: LogoWidgetCarouselsFactory.create(sectionItem.content)
        });
      case SectionTypes.SEO_WIDGET_CAROUSELS:
        return SectionFactory.create({
          type: sectionItem.type,
          content: SeoWidgetCarouselsFactory.create(sectionItem.content)
        });
    }
  }

  static generate(sectionData: ISection[]) {
    const filterSectionData = this.getValidateItems(sectionData);
    return filterSectionData.map((item: ISection) => {
      return this.generateSectionItem(item);
    });
  }
}
